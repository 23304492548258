import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnDestroy {
  title = 'TrainToGo';
  private readonly _subscription = new Subscription();

  constructor(private readonly _router: Router) {
    this._subscription.add(
      this._router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
    );
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
